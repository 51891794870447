$(function(){
  var $container = $('.container');
  if($container.attr('id') == 'blog' || $container.attr('id') == 'archive'){
    var count = 6;
    $(window).scroll(function () {
      var $blog = $('#blog-columns');
      var blog = document.getElementById("blog-columns");
      var rect = blog.getBoundingClientRect();
      var wHeight = $(window).height();
      if (rect.bottom - 1200 <= wHeight) {
        var data = {
      		action: 'getPosts',
      		variable : count,
          cat : $blog.attr('category'),
          post_type : 'post',
      	};
      	$.ajax({
      		type: "POST",
      		dataType: 'html',
      		data: data,
      		url: foundation.ajax_url,
      		success: function (response) {
            $(response).appendTo('#blog-columns');
      		}
      	});
        count = count + 3;
      }
    });
  }

  if($container.attr('id') == 'testimonials-archive'){
    var count_2 = 6;
    $(window).scroll(function () {
      var $testimonials = $('#testimonials-columns');
      var testimonials = document.getElementById("testimonials-columns");
      var rect = testimonials.getBoundingClientRect();
      var wHeight = $(window).height();
      if (rect.bottom - 1200 <= wHeight) {
        var data = {
      		action: 'getPosts',
      		variable : count_2,
          cat : '',
          post_type : 'testimonials',
      	};
      	$.ajax({
      		type: "POST",
      		dataType: 'html',
      		data: data,
      		url: foundation.ajax_url,
      		success: function (response) {
            $(response).appendTo('#testimonials-columns');
      		}
      	});
        count_2 = count_2 + 3;
      }
    });
  }
});
