function load_slick($this) {
  var width = $(window).width();
  if (width < 768) {
    if (!$this.hasClass("slick-initialized")) {
      $this.slick({
        slidesToShow: 1,
        adaptiveHeight: true,
        lazyLoad: 'ondemand'
      });
      $this.removeClass("blog-posts-section-new");
    }
  } else {
    if ($this.hasClass("slick-initialized")) {
      $this.slick("unslick");
    }
  }
}

$(document).ready(function() {
  var ready = true;
  var width = $(window).width();
  var $slider = $(".slider-golf");
  var $ball = $("#golf-ball");

  if (width >= 1200) {
    if (!$slider.hasClass("slick-initialized")) {
      $slider.slick({
        arrows: true,
        dots: true,
        draggable: false,
        autoplay: false,
        lazyLoad: 'ondemand',
        infinite: false
      });
    }
  } else {
    if (!$slider.hasClass("slick-initialized")) {
      $slider.slick({
        arrows: true,
        dots: true,
        draggable: true,
        autoplay: false,
        lazyLoad: 'ondemand',
        infinite: false
      });
    }
  }

  // See if Hashtag and go to that slide
  if (location.hash) {
    var num = location.hash.substr(1);
    $slider.slick("slickGoTo", parseInt(num));
  }

  // On before slide change
  if (width >= 1200) {

    var sliderNext = function() {
      if (ready === true) {
        // Ball Roll off screen Animation
        $("#golf-clug-line").addClass("swing");
        setTimeout(function() {
          $ball.addClass("roll-off");
          $ball.find(".svg").addClass("ball-spin");
        }, 1000);

        setTimeout(function() {
          ready = false;
          $slider.slick("slickNext");
          $ball.addClass("reset-ball-left").removeClass("roll-off");
          $("#golf-clug-line").removeClass("swing");
        }, 2900);

      }
    };

    var sliderPrev = function() {
      $slider.slick("slickPrev");
    };

    var sliderAfterChange = function(event, slick, currentSlide) {
      if (ready === false) {
        $ball.removeClass("reset-ball-left");
        ready = true;
        $("#menu").removeClass("active");
        $("#header-right").removeClass("active");
        setTimeout(function () {
          $ball.find(".svg").removeClass("ball-spin");
        }, 2900);
      }
      history.replaceState(undefined, undefined, "#" + currentSlide);
    };

    // Events
    $("#home-slider-prev").on("click", sliderPrev);
    $("#home-slider-next, #golf-ball, #button_link_slide_2").on("click", sliderNext);
    $slider.on("afterChange", sliderAfterChange);
  } else {
    $("#home-slider-prev").on("click", function(){
      $slider.slick("slickPrev");
    });
    $("#home-slider-next").on("click", function(){
      $slider.slick("slickNext");
    });
  }

});

// Misc Slides
$(document).ready(function() {
  var $feat_cotent = $("#feat-column-right");
  var paras = $feat_cotent.find("p").length;
  if (paras > 1) {
    $feat_cotent.append('<div class="read-more-feat-content"></div>');
  }

  $(".read-more-feat-content").on("click", function() {
    $feat_cotent.find("p").toggleClass("active");
    $(this).toggleClass("active");
  });
});

$(function() {
  var $scrollElem = $("#arrow-down-toggle-scroller");
  $(
    "#arrow-down-toggle-scroller, #featured-projects-hero-bg, #custom_greens_solutions_hero_button"
  ).on("click", function() {
    $("html, body").animate(
      {
        scrollTop: $scrollElem.offset().top - 70
      },
      1000
    );
  });
});

$(window)
  .resize(function() {
    load_slick($(".golf-balls-boxes"));
  })
  .resize();

// Fith Slide Animation
$(".circle-plus").click(function() {
  if (
    $(this)
      .children()
      .hasClass("circle-turn")
  ) {
    $(".text-wrap").hide("slide");
    $(".circle").removeClass("circle-turn");
  } else {
    $(".text-wrap").hide("slide");
    $(".circle").removeClass("circle-turn");
    $(this)
      .next()
      .slideToggle("slow");
    $(this)
      .find(".circle")
      .addClass("circle-turn");
  }
});

$(function() {
  if ($("body").hasClass("page-template-custom-greens-solutions")) {
    var href = window.location.href;
    href = href.split("#");
    if (typeof href[1] !== "undefined") {
      var width = $(window).width();
      var neg = 175;

      $("html, body").animate(
        {
          scrollTop: $("#" + href[1]).offset().top - neg
        },
        1000
      );
    }
  }
});
