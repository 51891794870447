$(function(){
  $('#menu-toggler').on('click', function(){
    $('#menu').toggleClass('active');
    $(this).closest('#header-right').toggleClass('active');
  });
  $('#menu-main-menu .menu-item-has-children > a').append('<div class="toggle-submenu"></div>');

  $('.toggle-submenu').on('click', function(e){
    e.preventDefault();
    $(this).toggleClass('open');
    $(this).closest('.menu-item').toggleClass('open');
  });

  //if(! $('body').hasClass('home')){
    if($(window).width() > 1199){
      $('#menu').addClass('active').addClass('opened');
      $('#header-right').addClass('active');
    }
    $(window).resize(function(){
      if($('#menu').hasClass('opened')){
        if($(window).width() < 1200){
          $('#menu').removeClass('active').removeClass('opened');
          $('#header-right').removeClass('active');
        }
      } else {
        if($(window).width() > 1199){
          $('#menu').addClass('active').addClass('opened');
          $('#header-right').addClass('active');
        }
      }
    }).resize();
  //}
});
