jQuery(document).foundation();

// **** Flex Video ****
//  jQuery( 'iframe[src*="youtube.com"]').wrap("<div class='flex-video widescreen'/>");
//  jQuery( 'iframe[src*="vimeo.com"]').wrap("<div class='flex-video widescreen vimeo'/>");

// **** Joyride demo ****
//  $('#start-jr').on('click', function() {
//    $(document).foundation('joyride','start');
//  });

// **** Sticky Footer ****
//  $(window).bind(' load resize orientationChange ', function () {
//     var footer = $("#footer-container");
//     var pos = footer.position();
//     var height = $(window).height();
//     height = height - pos.top;
//     height = height - footer.height() -1;
//
//     function stickyFooter() {
//       footer.css({
//           'margin-top': height + 'px'
//       });
//     }
//
//     if (height > 0) {
//       stickyFooter();
//     }
//  });
