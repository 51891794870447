$(function(){

/*
  Options:

#############################
  Required
#############################

  -- Slide in Side = default left
    -- Possible options "top", "left", "right", "bottom"

  -- Slide in Speed = default 400
    -- Options any miliseconds

  -- Optional Open/Close Button = default Create on for them
    -- Any object with a div or class

  -- Width of Menu = default 100%
    -- Any number

#############################
  Non Essentails
#############################

  -- Fixed or Static = Default Fixed
    -- Non essential for now

  -- Animation
    -- Non essential for now

  -- Optional Button Styling
    -- Non essential for now



*/

  $.fn.mobile_menu = function ( options ) {

    // Define Default settings:
    var defaults = {
      side: 'left',
      speed: '0.5s',
      toggler: '.menu-toggle',
      width: '100%',
      height: '100%',
      buttonStyles: true,
    };

    var initals = {
      toggleButton: '<div class="menu-toggle"></div>',
      menuWrap: '<div class="offcanvas-menu-wrap"></div>',
    };

    var $toggler;
    var offcanvas;

    var $this = $(this);

    var settings = $.extend( {}, initals, defaults, options );

    if(settings.toggler == defaults.toggler){
      $this.before(settings.toggleButton);
    }

    /* Determine if you want toggler Styles */
    $toggler = $(settings.toggler);
    if(settings.buttonStyles){
      $toggler.addClass('offcanvas-toggle');
    }

    switch ( settings.side ) {
      case "left":
        offcanvas = 'translateX(-100%)';
        break;
      case "top":
        offcanvas = 'translateY(-100%)';
        break;
      case "right":
        offcanvas = 'translateX(100%)';
        break;
      case "bottom":
        offcanvas = 'translateY(100%)';
        break;
    }

    var styles = {
      'transform' : offcanvas,
      'transition' : settings.speed,
      height : settings.height,
      width : settings.width,
    };

    return this.each(function() {

        $this.addClass('offcanvas-menu').wrap(settings.menuWrap);
        $this.parent().css( styles );
        console.log($toggler);
        $toggler.on('click', function(){
          console.log('open/close');

          $(this).toggleClass('offcanvas-active');
          console.log($this);
          $this.closest('.offcanvas-menu-wrap').toggleClass('offcanvas-open');

        });


    });
  }


  $('#mobile_menu').mobile_menu({
    side: 'bottom',
  });


});
