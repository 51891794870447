(function ($) {

  if ($('.smg-toggle-section').length < 1) return;

  var toggle = function () {
    $(this).next().slideToggle(400, function () {
      $(this).closest('.woocommerce-tab-section').toggleClass('open');
    });
  };

  $('.smg-toggle-header').on('click', toggle);


  if($('.woocommerce-product-rating .woocommerce-review-link').length < 1) return;

  var open_and_scroll_to = function (event) {
    event.preventDefault();

    var $reviewsTab = $('#section-reviews');

    $reviewsTab.find('.woocommerce-tab-section-content').slideDown(400, function () {
      if( ! $reviewsTab.hasClass('open')) {
        $reviewsTab.addClass('open');
      }
      $('html, body').animate({
        scrollTop: $reviewsTab.offset().top - $('#header').height() - $('#menu').height()
      }, 500);
    });
  };
  $('.woocommerce-product-rating .woocommerce-review-link').on('click', open_and_scroll_to);


})(jQuery);
